import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import VueHead from 'vue-head'
import VueSocialSharing from 'vue-social-sharing'
import VueScrollTo from 'vue-scrollto';
import Filters from './services/filters'

Vue.use(VueHead)
Vue.use(VueSocialSharing);
Vue.use(VueScrollTo)
Vue.use(Buefy) // Buefy css / components

Vue.config.productionTip = false
Filters.init(Vue)

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    }
    window.addEventListener('scroll', f);
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
