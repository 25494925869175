<template>
  <section class="hero">
    <div class="gradient" v-if="gradient === true"></div>
    <div class="background-mask"><svg viewBox="0 0 1376 586" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.000103771 104.657C457.785 104.657 1044.09 122.748 1376 0.000240587L1376 542.254L1376 584.096L1376 586C1376 586 1261.44 586 985.833 586C710.224 586 627.541 586 398.573 586C169.605 586 0.000229097 586 0.000229097 586L0.000103771 104.657Z" fill="#E4EDF1"/></svg></div>
    <div class="container">
      <div class="columns">
        <div class="column is-three-fifths">
          <div class="panel-text">
            <h2>Applying for Good Causes Funding</h2> 
            <h3>Are you doing good in your community?</h3>
            <p>National Lottery's primary aim is to raise funding for Good Causes, helping make a difference to communities, projects and individuals in the areas of Youth.</p>
            <a class="button is-primary" href="https://www.lottery.ie/useful-info/apply-for-good-causes-funding">How to apply for funding</a>
          </div>
        </div>
        <div class="column">
          <div class="panel-image">
            <img src="@/assets/logo_good_causes_hands.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
 
<script>
export default {
  name: 'HowToApplyPanel',
  props: {
    gradient: Boolean,
  }
}
</script>

<style lang="scss" scoped>
  section {
    position: relative;
    margin-top: 0em;
    //background-color: #E4EDF1;
    overflow: hidden;
    .gradient { 
      background: linear-gradient(90deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
      opacity: 0.9;
      position: absolute;
      top: 0;
      right:0;
      bottom:0;
      left:0;
    }
    .background-mask{
      position: absolute;
      top: 0;
      right:0;
      width: 100%;
      //height: 100px;
    }
  }
  .container{
    margin-top: 4em;
    //background-color: #E4EDF1;
  }
  .panel-image{
    width: 100%;
    img{
      padding: 20%
    }
  }
  .panel-text {
    margin-bottom: 1em;
  }
  h2 {
    margin: 5em 0 0 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  h3 {
    margin: 0.5em 0 0 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 32px;
  }
  p {
    margin: 1em 0;
  }

@media only screen and (max-width: 768px) {
  h2{
    margin: 1em 0 0 0;
  }
  section .background-mask{
      height: 420px;
      background-color: #E4EDF1;
  }
}
@media only screen and (max-width: 1009px) {
  .panel-text {
    padding: 0 1.5em;
  }
}
</style>

