<template>
  <section>
    <StoriesPanel :gradient="true" :stories="vm.articles" />
    <ExplorePanel />
    <HowToApplyPanel />
  </section>
</template>

<script>
import { observer } from "mobx-vue" 
import { store } from "@/services/store"
import StoriesPanel from '@/components/StoriesPanel.vue'
import ExplorePanel from '@/components/ExplorePanel.vue'
import HowToApplyPanel from '@/components/HowToApplyPanel.vue'

export default observer({
  name: 'Home',
  components: {
    StoriesPanel,
    ExplorePanel,
    HowToApplyPanel
  },
  data: () => ({
    vm: store,
  }),
  mounted() {
    this.vm.activeArticle = false;
    this.vm.fetchHome()
  }
})
</script>


