<template>
  <section :class="['hero', gradient === true ? 'gradient' : 'clipping-curve']">
    <div class="container">
      <div class="hero-body">
        <h2 :class="['title', gradient === true ? '' : 'dark']">
          Highlighted Stories
        </h2>
        <div class="slider-container">
          <div class="slider">
            <router-link :to="`/${$route.params.year}/article/${story.county}/${story.slug}`" class="slide" v-for="(story, idx) in stories" v-bind:key="idx">
              <div class="slide-pill">{{ story.county }}</div>
              <div class="slide-image">
                <img :src="`${cdnUrl}/photos/360/${story.image}`" />
              </div>
              <div class="slide-footer">
                <h3>{{ story.title }}</h3>
                <p v-html="story.excerpt"></p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CFG from '@/services/config';

export default {
  name: 'StoriesPanel',
  props: {
    gradient: Boolean,
    stories: Array,
  },
  data: () => ({
    cdnUrl: CFG.cdnUrl
  })
}
</script>

<style lang="scss" scoped>
section {
  margin-top: 3em;
}
.container {
      margin: 0 auto;
  //width: 100%;
}
.hero-body {
  text-align: center;
  overflow: hidden;
}
h2 {
  color: #FFF;
  font-weight: 900;
  font-size: 30px;
  line-height: 32px;
  width: 100%;
  margin: 1em auto 1em auto; 
}
h2.dark {
  color: #2D4550;
}
.gradient { 
  background: linear-gradient(102.3deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
  opacity: 0.9;
}
.clipping-curve{
  background: #E4EDF1;
}
.slider-container {
  height: 420px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
.slider {
  height: 420px;
  display: inline-flex;
}
.slide {
  color: #2D4550;
  width: 300px;
  height: 460px;
  margin: 1em;
  position: relative;
}
.slide-image {
  border-radius: 1em 1em 0 0;
  height: 200px;
  overflow: hidden;
}
.slide-footer {
  height: 188px;
  padding: 1em;
  background-color: #FFF;
  text-align: left;
  border-radius: 0 0 1em 1em;
  overflow: hidden;
  h3 {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}
.slide-pill {
  position: absolute;
  right:0;
  top: 1em;
  padding: 0.5em 2em;
  background-color: #C4DC33;
  border-radius: 0.5em 0 0 0.5em;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
}

.slide {
  width: 300px;
  height: 460px;
}
.hero-body {
  padding: 2rem 1rem;
}
@media only screen and (min-width: 1024px) {
  .slide {
    width: 360px;
    height: 400px;
  }
  .slide-footer {
    height: 160px;
  }
  .hero-body{
    padding: 3rem 1.5rem;
  }
}
@media only screen and (max-width: 1023px) {
  .container {
    max-width: 100%;
  }
}
</style>

