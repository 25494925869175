<template>
  <section class="section">
    <div :class="['icon', `icon-${icon}`]">
      
    </div>

    <div class="text-container" v-if="subtitle">
      <div class="countup" v-if="endVal > 0">
        <ICountUp
          :delay="delay"
          :endVal="endVal"
          :options="{...options, ...{prefix, suffix}}"
          @ready="onReady"
        />
      </div>
      <div class="countup" v-if="endVal < 1">
        {{ prefix }} {{ suffix }}
      </div>
      <h2 v-if="title">{{ title }}</h2>
      <p>{{ subtitle }}</p>
    </div>


  </section>
</template>

<script>
import ICountUp from 'vue-countup-v2';

export default {
  name: 'StatsPanel',
  props: {
    endVal: Number,
    icon: String,
    title: String,
    subtitle: String,
    prefix: String,
    suffix: String
  },
  components: {
    ICountUp
  },
  data:() => ({
      delay: 200,
      //endVal: 120500,
      options: {
        duration: 0.8,
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
  }),
  methods: {
    onReady: function(instance) { // , CountUp
      const that = this;
      instance.update(that.endVal + 1);
    }
  }
}
</script> 

<style lang="scss" scoped>
.section{
   padding: 1em;
   display: flex;
   //align-items: top;
}
.icon{ 
  flex-basis: 58px;
  flex-grow: 0;
  flex-shrink: 0;

  background-repeat: no-repeat;
  background-size: contain;
  width: 58px;
  height: 58px;
  margin-right: 1em;
}
.icon-coin{
  background-image: url('~@/assets/icon_coin.png')
}
.icon-hands{
  background-image: url('~@/assets/icon_shake_hand.png')
}
.text-container{
  p {
    font-size: 1.125em;
    color: #555;
  }
  .countup {
    margin: 0;
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
  }
  h2 {
    margin: 0;
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 768px) {

}
</style>
