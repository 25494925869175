
const copy = {
  home:{
    map: {
      title: 'Explore the Good Causes Across Ireland',
      subtitle: null,
    },
    heading: {
      icon: 'placeholder',
      title: 'Good Causes Ireland 2020'
    },
    stat_1:{
      icon: 'placeholder',
      title: '€254 M',
      subtitle: 'Raised by players in 2020',
    },
    stat_2:{
      icon: 'placeholder',
      title: 'Over 3,500 Good Causes',
      subtitle: 'Supported across Ireland in 2020',
    },
    news: {
      title: 'Last week players raised an estimated €5.2 Million for Good Causes!',
      paragraph: 'Almost 30c from every €1 spent on National Lottery games goes back to thousands of incredible Good Causes in communities all over Ireland in the areas of Health, Sport, Arts, Irish Language, Heritage, Children and Youth. We are delighted that National Lottery players have raised an estimated €6 billion for Good Causes across the country since we began selling our first products back in 1987.',
    }
  }
}

const counties = [
  'Carlow',
  'Cavan',
  'Clare',
  'Cork',
  'Donegal',
  'Dublin',
  'Galway',
  'Kerry',
  'Kildare',
  'Kilkenny',
  'Laois',
  'Leitrim',
  'Limerick',
  'Longford',
  'Louth',
  'Mayo',
  'Meath',
  'Monaghan',
  'Offaly',
  'Roscommon',
  'Sligo',
  'Tipperary',
  'Waterford',
  'Westmeath',
  'Wexford',
  'Wicklow'	
]

export  {
  copy,
  counties
} 