import * as axios from 'axios';
import CFG from './config';

const instance = axios.create({
  baseURL: CFG.apiUrl,
  timeout: 4000,
  //withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
  // other options
});

export default instance;