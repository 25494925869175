<template>
<section id="Main" class="section">
  <div class="container" v-if="!vm.activeArticle">
    <div class="columns">
      <div class="column is-three-fifths">
        <MapIreland @setActiveCounty="setActiveCounty" v-bind:activeCounty="vm.activeCounty" :coords="vm.coords" />
      </div>
      
      <div class="column info-panel">
          <div class="heading-logo">
            <!-- <img src="@/assets/logo_good_causes.png" /> -->
            <h2>Good Causes {{ vm.activeCounty === 'Ireland' ? 'Ireland' : vm.activeCounty }} 2020</h2>
          </div>

          <transition name="slideinout">
          <div class="button-close" v-if="vm.activeCounty != 'Ireland'" @click="vm.activeCounty = 'Ireland'; goTo(`/${$route.params.year}`)">
            <img src="@/assets/icon_cross.png" />
          </div>
          </transition>

          <!-- County View //-->
          <transition name="fade" :duration="{ enter: 1000, leave: 0 }">
          <div id="contentStart" class="info-content" v-if="vm.activeCounty !== 'Ireland'"> <!-- :endVal="((idx+1)*1000)-1" -->
              <div class="county-info"><p>Good Causes funding is used to support a wide range of groups and organisations in the areas of Sport, Arts, Heritage, Youth, Health, Community, Housing & Irish Language.
Here are some highlights on Good Causes {{ vm.activeCounty }} in 2020</p></div>
             <StatsPanel :endVal="(stat.amount)-1" :prefix="stat.prefix" :suffix="stat.suffix" :title="stat.title" :subtitle="stat.body" :icon="stat.icon" v-for="(stat, idx) in vm.stats" v-bind:key="idx" />
          </div> 
          </transition>

          <!-- Ireland View //-->
          <transition name="fade" :duration="{ enter: 1000, leave: 0 }">
          <div id="contentStart" class="info-content" v-if="vm.activeCounty === 'Ireland' ">
            <StatsPanel :endVal="253" prefix="€" suffix=" M" :subtitle="copy.home.stat_1.subtitle" icon="coin" />
            <StatsPanel :endVal="3499" prefix="Over " suffix=" Good Causes" :subtitle="copy.home.stat_2.subtitle" icon="hands" />
            <InfoPanel :title="copy.home.news.title" :paragraph="copy.home.news.paragraph" />
          </div>
          </transition>

      </div>
    </div>
  </div>

  <router-view :key="$route.fullPath" />

</section>
</template>

<script>
import { observer } from "mobx-vue" 
import { store } from "@/services/store"

import MapIreland from '@/components/MapD3.vue'
import StatsPanel from '@/components/StatsPanel.vue'
import InfoPanel from '@/components/InfoPanel.vue'

import { copy } from '@/services/data';
import { counties } from '@/services/data';

export default observer({
  name: 'AppIndex',
  components: {
    MapIreland,
    StatsPanel,
    InfoPanel
  },
  data: () => ({
    vm: store,
    copy,
    counties
  }),
  head: {
    title: function () {
      return {
        inner: this.vm.metaTitle
      }
    }
  },
  mounted() {

  },
  methods: {
    goTo(routeName) {
      this.$router.push(routeName);
    },
    setActiveCounty(county){
      if (county !== 'Ireland') {
        this.vm.fetchArticles(county)
        this.goTo(`/${this.$route.params.year}/county/${county}`)
      } else {
        this.goTo(`/${this.$route.params.year}/`)
      }
    }
  }
}); 
</script>

<style lang="scss" scoped>
section.section {
  padding: 0.75em 0 0 0;
}
.button-close{
  width: 48px;
  height: 48px;
  position: absolute;
  top: 576px;
  right: 0px;
  cursor: pointer;
}

.columns .column:first-of-type{
  z-index: 99;
}

.info-panel{
  .heading-logo{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    h2{
      font-weight: 900;
      font-size: 30px;
      line-height: 32px;
      width: 60%;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 20%;
    }
  }
}
.county-info{
    margin-left: 74px;
}
@media only screen and (min-width: 768px) {
  .button-close{
    top: 0;
  }
}
@media only screen and (max-width: 768px) {
  #Main {
   margin-top: 82px;
  }
  .info-panel{
    min-height: 600px;
  }
}

@media only screen and (max-width: 1009px) {
  .info-content, .heading-logo {
    padding: 0 1.5em;
  }
}
</style>
