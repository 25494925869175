<template>
  <section class="hero">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="panel-image">
            <img src="@/assets/lottery-awards-1.jpg" />
          </div>
        </div>
        <div class="column is-three-fifths">
          <div class="panel-text">
            <h3>Good Causes Awards</h3> 
            <!-- <h3>Regional Winners Announced</h3> -->
            <p>National Lottery's primary aim is to raise funding for Good Causes, helping make a difference to communities, projects and individuals in the areas of Youth, Sports, Recreation, Amenities, Health, Welfare, Arts, Culture, National Heritage and the Irish Language. </p>
            <a class="button is-primary" href="https://www.lottery.ie/good-causes-awards">Explore Good Causes Awards</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
 
<script>
export default {
  name: 'ExplorePanel',
  props: {

  }
}
</script>

<style lang="scss" scoped>
  section {
    margin-top: 4em;
    padding-top: 0.75em;
  }
  .panel-image{
    width: 90%;
  }

  h2 {
    margin: 3em 0 0 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  h3 {
    margin: 0.5em 0 0 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 32px;
  }
  p {
    margin: 1em 0;
  }

  .columns {
    max-width: 100%;
  }
  button.is-primary{
    max-width: 100%;
    margin-bottom: 2rem;
  }
@media only screen and (min-width: 769px) {
  button.is-primary{
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1009px) {
  .panel-text {
    padding: 0 1.5em;
  }
  .panel-image{
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1023px) {
  .hero .container {
    max-width: 100%;
  }
}
</style>

