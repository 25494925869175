<template>
  <footer>
    <div class="dark-bar">
      <div class="container">
        <div class="icon icon-age"></div>
        <p>Play responsibly, play for fun. National Lottery funds Good Causes around Ireland.</p> 
        <span class="pipe">|</span>
        <a href="https://www.lottery.ie/useful-info/play-responsibly">Responsible play information</a>
      </div>
    </div>
    <div class="container disclaimer">
      <p>
        We are committed to operating the National Lottery in a socially responsible way. 
        Our aim is to provide exciting and engaging lottery games that bring fun and entertainment to everyone,
        while ensuring as far as possible that individuals play within their means and that vulnerable and persons less than 18 years of age are protected.
      </p> 
    </div>
    <nav class="footer-nav">
      <div class="container">
        <ul class="lang-choice">
          <!-- <li>Gaeilge</li>
          <li>English</li> -->
        </ul>
        <ul>
          <li><a href="https://www.lottery.ie/useful-info/cookies">Cookie Policy</a></li>
          <li><a href="https://www.lottery.ie/useful-info/privacy">Data Privacy Statement</a></li>
          <li><a href="https://www.lottery.ie/useful-info/terms-conditions">Terms &amp; Conditions</a></li>
        </ul>
        <ul>
          <li>©2021 National Lottery</li>
        </ul>
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'FooterDisclaimer',
  props: {

  }
}
</script>

<style lang="scss" scoped>
  footer {
    margin-top: 0;
  }
  .dark-bar {
    text-align: center;
    background-color: #333;
    color: #FFF;
    padding: 0.7em 0 0.3em 0;
    .container{
      display: inline-flex;
      align-items: center;
      .icon{ 
        background-repeat: no-repeat;
        background-size: cover;
        width: 32px;
        height: 32px;
        margin-right: 1em;
      }
      .icon-age{
        flex-basis: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        background-image: url('~@/assets/icon_age.png')
      }
      a{
        color: #AAA;
        text-decoration: underline;
      }
      .pipe{
        margin: 0 1em;
      }
    }
  }
  .disclaimer p{
    padding: 2.75em 0 5em 0;
    margin: 0 5em;
  }

  .footer-nav {
    padding: 1em 0 2em 0;
    a{ 
      color: #2D4550;
      text-decoration: underline;
    }
    .container{
      display: flex;
      justify-content: space-between;
      ul {
        display: inline-flex;
        &.lang-choice{
          li:first-of-type {
            border-right: 1px solid;
          }
        }
        li{
          padding: 0 0.5em
        }
      }
    }
  }

</style>