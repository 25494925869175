import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/:year',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:year/county/:county',
    name: 'County',
    component: () => import(/* webpackChunkName: "article" */ '../views/County.vue')
  },
  {
    path: '/:year/article/:county/:slug',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
 