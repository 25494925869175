<template>
  <nav class="nav-bar" :class="{ active: menuActive }">
    <div class="container">
      <ul class="nav-menu">
        <li>
          <router-link :to="`/${$route.params.year}`" class="nav-logo">
            <img src="@/assets/megamenu-logo.png" alt="" />
          </router-link>
        </li>
        <li class="nav-item">
          <a href="https://www.lottery.ie/draw-games" class="nav-link">Games</a>
        </li>
        <li class="nav-item">
          <a href="https://www.lottery.ie/draw-games/results/view" class="nav-link">Results</a>
        </li>
        <li class="nav-item">
          <a href="https://www.lottery.ie/news/good-causes" class="nav-link"> Good Causes </a>
        </li>
        <li class="nav-item">
          <a href="https://www.lottery.ie/news" class="nav-link">Winners</a>
        </li>
      </ul>

      <ul class="nav-end">
        <li class="nav-item">
          <a href="https://www.lottery.ie/useful-info/play-responsibly" class="nav-link">Responsible Play</a>
        </li>
        <li class="nav-item">
          <a href="https://www.lottery.ie/register" class="nav-link">Register</a>
        </li>
        <li class="nav-item">
          <a href="https://www.lottery.ie/account/login" class="nav-link">Sign in</a>
        </li>
      </ul>

      <div class="hamburger" @click="toggleMenu()">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationMain",
  props: {
    msg: String,
  },
  data: () => ({
    menuActive: false
  }),
  methods: {
    toggleMenu(){
      this.menuActive = !this.menuActive
    }
  }
};
</script>

<style lang="scss" >
.nav-bar {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 84px;
  .container {
    display: flex;
    flex: 1;
    position: relative;
  }
  ul {
    display: inline-flex;
    align-items: center;
    font-size: 1.125em;
  }
  li {
    list-style: none;
    padding: 0 1em;
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: #555;
  }
  .nav-logo {
    margin-right: 1em;
    img {
      
      min-width: 180px;
      //height: 84px;
      max-height: 82px;
    }
  }

  .nav-menu {
    width: 60%;
    li:first-of-type{
      padding: 0;
    }
  }
  .nav-end {
    width: 40%;
    justify-content: end;
    
    li:first-of-type {
      margin-right: auto;
      font-weight: 400;
    }
    li:last-of-type {
      border-left: 1px solid #333;
    }
  }
}
/* */
.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px; 
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

@media only screen and (max-width: 768px) {
  .nav-bar {
    flex-direction: row; 
    width: 100%;
    max-width: 100%; 
    overflow: hidden;
    height: auto;
    max-height: 82px;
    transition: max-height 0.25s ease-out;
    position:fixed;
    top:0;
    z-index:9999999;
    background-color: #FFF;
    &.active {
      //height: auto;
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    }

    ul {
      display: block;
    }
    .nav-end {
      margin-top: 91px;
      li:last-of-type {
        border-left: none;
      }
    }

    .nav-item {
        margin: 2.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        padding: 1em;
        position: absolute;
        right:0;
    }

  }

}
@media only screen and (max-width: 1023px) {
  .container {
    max-width: 100%;
  }
}
</style>
