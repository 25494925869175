<template>
  <section class="section">
    <h2>{{ title }}</h2>
    <p>{{ paragraph }}</p>
  </section>
</template>

<script>
export default {
  name: 'InfoPanel',
  props: {
    title: String,
    paragraph: String
  }
}
</script>

<style lang="scss" scoped>
.section{
  padding: 1em;
}
h2 {
  margin: 0;
  font-weight: 900;
  font-size: 30px;
  line-height: 32px;
}
p {
  margin-top: 1em;
}
</style>
