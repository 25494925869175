<template>
  <section>
    <p>Redirecting...</p>
  </section>
</template>

<script>
import { observer } from "mobx-vue" 
import { store } from "@/services/store"
import CFG from '@/services/config';


export default observer({
  name: 'Index',
  components: {

  },
  data: () => ({
    vm: store,
  }),
  mounted() {
    this.goToYear(this.vm.activeYear)
  },
  methods: {
    goToYear(year){
      if (year>0) {
        window.location.href = `${CFG.baseUrl}/${year}`
      }
    }
  }
})
</script>


