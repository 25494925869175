import axios from "./axios";
import CFG from "./config";
//import { storeSave, storeGet } from "../services/localstore";

const errorHandling = error => {
  console.log("Axios Error: ", error);
  return error; //.response.data; // see https://github.com/axios/axios/issues/960
};

const dataService = {
  getApi(route) {
    //axios.defaults.baseURL = `${CFG.apiUrl}`;
    //return storeGet("api_token").then(token => {
    //  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return axios
        .get(`${CFG.apiUrl}/${route}`)
        .then(res => res.data)
        .catch(errorHandling);
    //});
  },
};


export { dataService };
