<template>
  <div class="section map-section" v-scroll="handleScroll">
    <div :class="['map-container']">
      <div class="gradient">
        <div class="clipping-mask"></div>
      </div>
      <h1>Good Causes in {{ vm.activeCounty === 'Ireland' ? activeHover === false ? 'Ireland' : activeHover : vm.activeCounty }}</h1>
      <div class="map">
        <SvgMap id="svgMap" ref="svgMap" />
        <transition name="dots" :duration="{ enter: 2000, leave: 0 }">
          <span v-if="coords.length >0">
            <div class="dot" :style="`left:${dot.x}%;top:${dot.y}%`" v-for="(dot, idx) in coords" v-bind:key="idx">
              <div class="dot-out"></div>
              <div class="dot-in"></div>
            </div> 
          </span>
        </transition>
      </div>
      <div :class="['menu-container', stickyMenu ? 'sticky' : '']">
        <ul>
          <li :class="['nav-link', 'Ireland' === vm.activeCounty ? 'active' : '']" @click="clickedCounty(false)">Across Ireland</li>
          <li :class="['nav-link', county === vm.activeCounty ? 'active' : '']"
              v-for="(county, idx) in counties" v-bind:key="idx"
              @click="clickedCounty(county)"
          >
            <div>{{ county }}</div>
          </li>
        </ul> 
      </div>
    </div>
  </div>
</template>

<script>
//import {scroller} from 'vue-scrollto/src/scrollTo'
import { observer } from "mobx-vue" 
import { store } from "@/services/store"
const d3 =  {...require('d3'), ...require('d3-fetch')}
import SvgMap from './SvgMap'
import { counties } from '@/services/data';

export default observer({  
  name: 'MapIreland',

  components: {
    SvgMap
  },
  props: {
    activeCounty: String,
    coords: Array
  },
  data: () => ({
    vm: store,
    activeHover: false,
    counties,
    svgMap: null,
    mw: 600,
    mh: 760,
    defaultScale: 0.65,
    scale: 3,
    lastActive: null,
    stickyMenu: false
  }),
  mounted() {
    this.initMap()
  },
  created () {
    //window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    //window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    activeCounty: function (newVal) { // newVal, oldVal
      this.updateMap(newVal)
    }
  },
  methods: {
    handleScroll(){
      const Y = window.scrollY;
      //console.log(Y)
      if(Y > 502){
        this.stickyMenu = true
      } else {
        this.stickyMenu = false
      }
    },
    scrollTo(){
      const w = window.innerWidth;
      if(w <769){
        window.scrollTo(0, 510);
      }
      //const scrollMain = scroller()
      //scrollMain('#contentStart')
    },
    updateMap(newVal){
        this.activeHover = false;
        const countyId = this.counties.includes(newVal) ? newVal : this.vm.activeCounty;
        if(newVal === this.vm.activeCounty){
          d3.select(this.svgMap).selectAll("#republic").selectAll("path").attr("class", "");
        }
        let countyPath = d3.select(this.svgMap).select(`#${countyId}`);
        this.zoomed(countyPath)
    },
    initMap(){
      const xml = this.$refs.svgMap.$el
      this.svgMap = xml.getElementsByTagName("g")[0]; /* set svgMap to root g */
      d3.select(this.svgMap).selectAll("#NI") /* Group Northern Ireland together */
        .attr("class", "region NI");
      d3.select(this.svgMap).selectAll("#republic") /* Group Republic of Ireland together */
        .attr("class", "region republic")
        .on("mouseover", this.handleMouseOver)
        .on("mouseout", this.handleMouseOut);
      let this_ = this;
      d3.select(this.svgMap).selectAll("#republic").selectAll("path")
        .on("click", function () { 
          //this_.zoomed(d3.select(this));
          this_.clickedCounty(d3.select(this).attr('id'));
        });
      d3.select(this.svgMap).selectAll("#NI").selectAll("path")
            .attr("style", 'fill: #e7eff3; stroke: #e7eff3; stroke-width: 0.75;');
      //
      const hasCounty = this.$route.params.county;
      if(hasCounty){
        this.updateMap(hasCounty)
      }
    },
    clickedCounty(county){
      //console.log('clickedCounty', county)
      //const countyId = this.counties.includes(county) ? county : this.vm.activeCounty;
      //let countyPath = d3.select(this.svgMap).select(`#${countyId}`);
      //this.zoomed(countyPath)
      if(this.counties.includes(county) && this.vm.activeCounty != county){
        this.vm.activeCounty = county;
        this.$emit('setActiveCounty', county);
        this.scrollTo();
      } else {
        this.vm.activeCounty = 'Ireland';
        this.$emit('setActiveCounty', 'Ireland');
      }
    },
    handleMouseOver(e){
        if(this.vm.activeCounty === 'Ireland'){
          this.activeHover = d3.select(e.target).attr('id')
          d3.select(e.target).attr("style", 'fill: #e7eff3')
        }
    },
    handleMouseOut(e){
        if(this.vm.activeCounty === 'Ireland'){
          this.activeHover = false
          d3.select(e.target).attr("style", 'fill: rgb(211, 226, 233)')
        }
    },
    
    zoomed(d) {
      var xy = this.getBoundingBox(d);	/* get top left co-ordinates and width and height 	*/
      if ( !xy || d.classed("active")) {	/* if county is active reset map scale and county colour */
          const xml = this.$refs.svgMap.$el
          d3.select(xml).selectAll("#viewport")
            .transition().duration(750).attr("transform", "scale(" + this.defaultScale + ")");
          this.lastActive = "";
          d3.select(this.svgMap).selectAll("#NI").selectAll("path")
              .attr("style", 'fill: #e7eff3; stroke: #e7eff3; stroke-width: 0.75;opacity:1');
          d3.select(this.svgMap).selectAll("#republic").selectAll("path")
              .attr("style", 'fill: #d3e2e9; stroke: #c1d3db; stroke-width: 0.75;opacity:1');
          d.attr("class", '');
      } else {
        const activeCounty = d.attr("id");
        this.scale = Math.min(this.mw/xy[1], this.mh/xy[3], 3);	
        var tx = -xy[0] + (this.mw - xy[1]*this.scale)/(2*this.scale) -35; // last value is 1/2 viewport offset
        var ty = -xy[2] + (this.mh - xy[3]*this.scale)/(2*this.scale) -35; //
        const xml = this.$refs.svgMap.$el
        d3.select(xml).selectAll("#viewport")
            .transition().duration(750).attr("transform", "scale(" + this.scale + ")translate("+ tx +"," + ty + ")");
        d3.select(this.svgMap).selectAll("#republic").selectAll("path").attr("class", "");
        d.attr("class", "active");
        this.lastActive = activeCounty
        /** */
        d3.select(this.svgMap).selectAll("#NI").selectAll("path")
            .attr("style", 'fill: #e7eff3; stroke: #e7eff3; stroke-width: 0;opacity:0.25');
        d3.select(this.svgMap).selectAll("#republic").selectAll("path")
            .attr("style", 'fill: #d3e2e9; stroke-width: 0.25;opacity:0.5');
        d.attr("style", 'fill: #d3e2e9; stroke-width: 0.25;opacity:1');
      }
    },
    getBoundingBox(selection){
        /* get x,y co-ordinates of top-left of bounding box and width and height */
        const element = selection.node();
        if(!element) return
        const bbox = element.getBBox();
        const cx = bbox.x + bbox.width/2;
        const cy = bbox.y + bbox.height/2;
        return [bbox.x, bbox.width, bbox.y, bbox.height, cx, cy];
    }

  }
}); 
</script>

<style lang="scss" scoped>
.map-section {
  margin:0;
  padding:0;
}
h1 {
  color: #FFF;
  position: absolute;
  top:32px;
  left:32px;
  margin:0;
  padding:0; 
  font-weight: 900;
  font-size: 50px;
  line-height: 54px;
  width: 70%;
  z-index:999999;
  text-shadow: 0 0 2px #AAA;
}
.gradient{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: linear-gradient(270deg, #1C4240 -46.67%, #3D7A3B 10.14%, #4D9945 46.86%, #BDD630 101.55%);
  .clipping-mask{
    position: absolute;
    top:0;
    left:-10%;
    right:-10%;
    bottom:0;
    background: #FFF;
    clip-path: ellipse(100% 25% at 100% 100%);
  }
}
.map-container {
  position: relative;
  min-height: 800px;
  width: 100%;
  overflow: hidden;
}
.map{
  position: absolute;
  width: 100%;
  top:0;
  right:0;
  bottom:0;
  display: flex;
  svg{
    width: 100%;
    height: 100%;
  }
}

.menu-container{
  position: absolute;
  left:0;
  right:0;
  bottom:0;
  height: 4rem;
  //background: #FFF;
  ul{
    display: inline-flex;
    overflow-x: scroll;
    width: 100%;
    pointer-events: auto;
    li{
      pointer-events: auto;
      cursor: pointer;
      white-space: nowrap;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      padding: 0.5em 0.333em;
      color:#AAA;
      &.active{
        color: #2D4550;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    top:16px;
    left:16px;
    font-size: 36px;
    line-height: 38px;
    width: 60%;
  }
  .map-container {
    position: relative;
    min-height: 560px;
    width: 100%;
    overflow: hidden;
    &.sticky{
      position: fixed;
      top: -580px;
    }
  }
  .menu-container.sticky{
    position: fixed;
    top:82px;
    background-color: #FFF;
    z-index: 999999;
  }
}
@media only screen and (min-width: 1216px) {
  h1 {
    width: 55%;
  }
}
</style>
