/*****************
 *  Vue Filters  *
 *****************/


/**
 *  Truncate
 */
export function truncate(val, limit) {
  const pad = val.length > limit ? '...' : ''
  return `${val.slice(0, limit)}${pad}`
}


/**
 *  Registers the filters with vue
 */
export function init(Vue) {
  Object.keys(this.filters).forEach((filter) => {
    Vue.filter(filter, this.filters[filter]);
  });
}

export default {
  filters: {
    truncate,
  },
  init,
};
