<template>
  <div id="app">
    <NavigationMain />
    <GoodCauses />
    <FooterMain />  
  </div>
</template>

<script>
import NavigationMain from '@/components/NavigationMain.vue'
import FooterMain from '@/components/FooterMain.vue'
import GoodCauses from '@/views/GoodCauses'

export default {
  name: 'App',
  components: {
    NavigationMain,
    GoodCauses,
    FooterMain
  },
  head: {
    title: {
      inner: '',
      separator: ' ',
      complement: 'Good Causes | lottery.ie'
    },
  }
}
</script>

<style lang="scss" src="./assets/styles/App.scss"></style>

