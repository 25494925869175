import { action, observable } from 'mobx';
import { dataService } from './api'

export default class AppStore {
  @observable activeYear = 2020;
  @observable activeCounty = 'Ireland';
  @observable activeArticle = false;
  @observable metaTitle = '';
  @observable metaDescription = '';
  @observable article = {};
  @observable articles = [];
  @observable stats = [];
  @observable coords = [];
  // @action.bound setActiveCounty($county) {
  //   this.activeCounty = $county;
  // }

  @action.bound async fetchArticle(county, slug) {
    this.activeCounty = county
    this.articles = []
    this.stats = []
    this.coords = []
    const data = await dataService.getApi(`articles/${county}/${slug}`)
    this.activeArticle = true
    this.article = data.article
    this.articles = data.articles
  }

  @action.bound async fetchArticles(county) {
    this.activeCounty = county
    this.articles = []
    this.stats = []
    this.coords = []
    const data = await dataService.getApi(`county/${county}`)
    this.articles = data.articles
    this.stats = data.stats
    this.coords = data.coords
  }

  @action.bound async fetchHome() {
    this.activeCounty = 'Ireland'
    this.articles = []
    this.stats = []
    this.coords = []
    const data = await dataService.getApi(`featured/home`)
    this.articles = data.articles
  }

}

export const store = new AppStore(); 
 